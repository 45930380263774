import React from 'react';
import './scss/ConfirmationSignInInfo.scss';

export default function ConfirmationSignInInfo() {
  return (
    <div className="ConfirmationSignInInfo">
      <div>
        <p className="info-header">Are you a patient?</p>
        <p>
          Please check your TEXT MESSAGES or EMAIL for your care provider’s
          connection instructions. Contact your care provider’s office with
          additional questions.
        </p>
      </div>

      <div>
        <p className="info-header">
          Already have access to Amwell Private Practice?
        </p>
        <p>
          <a href="https://privatepractice.amwellnow.com/enrollment">
            Activate your Account
          </a>
          <span>or</span>
          <a href="https://privatepractice.amwellnow.com/now">
            Start a Visit
          </a>
        </p>
      </div>
    </div>
  );
}
