import React, { useState } from 'react';
import './scss/ConfirmationDetails.scss';
import { CaretIcon } from '../../icons';
import { isCodeFreemium } from '../../../lib/utils';
import { validateUser } from '../../../lib/validators/ValidatePracticeUsers';

export default function ConfirmationDetails({ order }) {
  /**
   * Order Plan Details
   */
  const PlanDetails = () => {
    const PlanTier = () => {
      const { subscription } = order;
      const { code, name } = subscription;
      const isFree = isCodeFreemium(code);
      let fixedName;
      if (name.includes('Amwell')) {
        fixedName = name.split('Amwell')[1].trim();
      } else {
        fixedName = name;
      }

      return (
        <span className="ConfirmationDetails-line">
          {isFree ? fixedName : 'Premium Plan'}
        </span>
      );
    };

    const PracticeUsersAmount = () => {
      const { addOnQuantity } = order;
      return (
        <span className="ConfirmationDetails-line">
          {addOnQuantity} Practice User{addOnQuantity > 1 ? 's' : ''}
        </span>
      );
    };

    return (
      <div className="ConfirmationDetails-box">
        <span className="ConfirmationDetails-heading">Plan</span>
        <PlanTier />
        <PracticeUsersAmount />
      </div>
    );
  };

  const ContactDetails = () => {
    const Company = () => {
      const { company } = order;
      return <span className="ConfirmationDetails-line">{company}</span>;
    };

    const PersonalContact = () => {
      const { firstName, lastName } = order;
      const fullName = `${firstName} ${lastName}`.trim();
      return <span className="ConfirmationDetails-line">{fullName}</span>;
    };

    const ContactEmail = () => {
      const { email } = order;
      return <span className="ConfirmationDetails-line">{email}</span>;
    };

    return (
      <div className="ConfirmationDetails-box">
        <span className="ConfirmationDetails-heading">Contact</span>
        <Company />
        <PersonalContact />
        <ContactEmail />
      </div>
    );
  };

  const BillingDetails = () => {
    const { subscription, billing } = order;
    const { code } = subscription;
    const { ccBrand, ccLastFour } = billing;
    const isFree = isCodeFreemium(code);

    return isFree ? (
      <div className="ConfirmationDetails-box">
        <span className="ConfirmationDetails-heading">Billing Info</span>
        <span className="ConfirmationDetails-line">FREE</span>
      </div>
    ) : (
      <div className="ConfirmationDetails-box">
        <span className="ConfirmationDetails-heading">Billing Info</span>
        <span className="ConfirmationDetails-line ccBrand">{ccBrand}</span>
        <span className="ConfirmationDetails-line">
          xxxx-xxxx-xxxx-{ccLastFour}
        </span>
      </div>
    );
  };

  const UsersDropdown = () => {
    const [viewPracticeUsers, setViewPracticeUsers] = useState(false);
    const { addOnQuantity, users } = order;
    const dropdownOpenClass = viewPracticeUsers ? 'open' : '';
    const toggleViewPracticeUsers = () =>
      setViewPracticeUsers(!viewPracticeUsers);

    const UserPreview = ({ user, index }) => {
      const { firstName, lastName, email } = user;
      const isValid = !validateUser(user).foundErrors.length;
      const fullUserName = `${firstName} ${lastName}`.trim();

      const UserHeading = () => (
        <span className="ConfirmationDetails-dropdown-user-heading">
          User {index + 1}
        </span>
      );

      const UserName = () =>
        fullUserName ? (
          <span className="ConfirmationDetails-dropdown-user-line">
            {fullUserName}
          </span>
        ) : null;

      const UserEmail = () =>
        email ? (
          <span className="ConfirmationDetails-dropdown-user-line">
            {email}
          </span>
        ) : null;

      const CompleteLater = () => (
        <span className="ConfirmationDetails-dropdown-user-line">
          Log in to complete sign up
        </span>
      );

      return (
        <div className="ConfirmationDetails-dropdown-user">
          <UserHeading />
          {isValid ? (
            <>
              <UserName />
              <UserEmail />
            </>
          ) : (
            <CompleteLater />
          )}
        </div>
      );
    };

    return (
      <div className={`ConfirmationDetails-dropdown ${dropdownOpenClass}`}>
        <button
          className="ConfirmationDetails-dropdown-handle"
          onClick={toggleViewPracticeUsers}
        >
          <span className="ConfirmationDetails-dropdown-handle-label">
            View {addOnQuantity} Practice User{addOnQuantity > 1 ? 's' : ''}
          </span>
          <span className="ConfirmationDetails-dropdown-handle-icon">
            <CaretIcon />
          </span>
        </button>
        <div className="ConfirmationDetails-dropdown-users">
          {users.map((user, i) => (
            <UserPreview key={`userPreview-${i}`} user={user} index={i} />
          ))}
        </div>
      </div>
    );
  };

  const OrderTopLine = () => {
    const { email } = order;
    return (
      <p className="ConfirmationDetails-topline">
        Subscription account details have been sent to{' '}
        <b>
          <i>{`${email}`}</i>
        </b>
        . Practice user access details will be sent to each practice user's
        email address within 30 minutes of registration.
      </p>
    );
  };

  return (
    <>
      <OrderTopLine />
      <div className="ConfirmationDetails">
        <div className="ConfirmationDetails-section">
          <PlanDetails />
          <ContactDetails />
          <BillingDetails />
        </div>
        <UsersDropdown />
      </div>
    </>
  );
}
