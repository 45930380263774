import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { StateContext } from '../../lib/providers';
import { SplashScreen } from '../../components/misc';
import {
  ConfirmationSignIn,
  ConfirmationSignInInfo,
} from '../../components/checkout/confirmation';
import './scss/Signin.scss';
import { ViewLayout } from '../../components/layout';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function Signin() {
  const state = useContext(StateContext);
  const { user } = state || {};

  /**
   * Redirect if the user is detected signed in
   */
  useEffect(() => {
    if (window) {
      if (user) navigate('/dashboard');
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Sign In</title>
      </Helmet>
      <ViewLayout type="signin">
        <div className="Signin">
          {user ? (
            <SplashScreen />
          ) : (
            <div className="flex-container">
              <ConfirmationSignIn />
              <ConfirmationSignInInfo />
            </div>
          )}
        </div>
      </ViewLayout>
    </>
  );
}
