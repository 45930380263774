import React, { useCallback, useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, navigate } from 'gatsby';
import { Amwell } from '../../../lib/services';
import { DispatchContext } from '../../../lib/providers';
import {
  APP_SET_USER_DATA,
  APP_SET_USER_SUBSCRIPTION_DATA,
} from '../../../lib/events/app/types';
import { CheckoutFormField } from '..';
import './scss/ConfirmationSignIn.scss';

const initErrorObject = {
  email: [],
  password: [],
  amplify: [],
};

export default function ConfirmationSignIn() {
  const dispatch = useContext(DispatchContext);
  const [signingIn, setSigningIn] = useState(false);
  const [requiresNewPassword, setRequiresNewPassword] = useState(null);
  const [errors, setErrors] = useState(initErrorObject);
  const [signInForm, setSignInForm] = useState({
    email: '',
    password: '',
  });
  const [newPasswordForm, setNewPasswordForm] = useState({
    password: '',
  });
  const signingInClass = signingIn ? 'signingIn' : '';
  const signInButtonText = signingIn ? 'Signing In...' : 'Sign In ›';

  const getUserInfoAndRedirect = useCallback(async () => {
    const { user, subscriptionData } = await Amwell.fetchCurrentUserInfo();
    dispatch({ type: APP_SET_USER_DATA, payload: { user } });
    dispatch({
      type: APP_SET_USER_SUBSCRIPTION_DATA,
      payload: { subscription: subscriptionData[0] },
    });
    await navigate('/dashboard');
  }, [dispatch]);

  const handleSignInError = useCallback(
    async (err) => {
      console.error('error signing in', { err });
      dispatch({ type: APP_SET_USER_DATA, payload: { user: false } });
      setSigningIn(false);
      return setErrors((val) => ({
        ...val,
        amplify: ['Incorrect username or password'],
      }));
    },
    [dispatch]
  );

  const handleSignInFormInputChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    setSignInForm((val) => ({ ...val, [name]: value }));
  };

  const handleNewPasswordFormInputChange = (evt) => {
    const { target } = evt;
    const { name, value } = target;
    setNewPasswordForm((val) => ({ ...val, [name]: value }));
  };

  const signInWithAmplify = useCallback(async () => {
    const { email, password } = signInForm;
    setSigningIn(true);
    try {
      const cognitoUser = await Auth.signIn(email, password);
      console.log('cognitoUser', cognitoUser);

      const { challengeName } = cognitoUser;
      if (challengeName && challengeName === 'NEW_PASSWORD_REQUIRED') {
        setSigningIn(false);
        return setRequiresNewPassword(cognitoUser);
      }

      return getUserInfoAndRedirect();
    } catch (err) {
      return handleSignInError(err);
    }
  }, [signInForm, getUserInfoAndRedirect, handleSignInError]);

  // Submits sign in form
  const handleSignIn = useCallback(
    // eslint-disable-next-line consistent-return
    async (evt) => {
      evt.preventDefault();
      const { email, password } = signInForm;
      setErrors(initErrorObject);
      console.log('handleSignIn', signInForm);
      if (!email)
        return setErrors((val) => ({
          ...val,
          email: ['Email cannot be empty'],
        }));

      if (!password)
        return setErrors((val) => ({
          ...val,
          password: ['Password cannot be empty'],
        }));

      await signInWithAmplify();
    },
    [signInForm, signInWithAmplify]
  );

  // Submits new password input
  const handleNewPassword = useCallback(
    async (evt) => {
      evt.preventDefault();
      const { password } = newPasswordForm;
      setErrors(initErrorObject);
      if (!password)
        return setErrors((val) => ({
          ...val,
          password: ['Password cannot be empty'],
        }));

      setSigningIn(true);
      try {
        const cognitoUser = await Auth.completeNewPassword(
          requiresNewPassword,
          password
        );
        console.log('cognitoUser', cognitoUser);
        console.log('New required password successful');
        return await getUserInfoAndRedirect();
      } catch (err) {
        console.error('New required password unsuccessful');
        return handleSignInError(err);
      }
    },
    [
      requiresNewPassword,
      newPasswordForm,
      getUserInfoAndRedirect,
      handleSignInError,
    ]
  );

  return (
    <div>
      {!requiresNewPassword ? (
        <form
          className={`ConfirmationSignIn ${signingInClass}`}
          onSubmit={handleSignIn}
        >
          <p className="ConfirmationSignIn-heading">
            Sign in to manage your Amwell Private Practice subscription
          </p>
          <CheckoutFormField
            label={'Email Address'}
            name={'email'}
            id={'signin-email'}
            value={signInForm.email}
            onChange={handleSignInFormInputChange}
            hideSymbol={true}
            errors={errors.email}
          />
          <CheckoutFormField
            label={'Password'}
            name={'password'}
            id={'signin-password'}
            value={signInForm.password}
            onChange={handleSignInFormInputChange}
            hideSymbol={true}
            type="password"
            errors={errors.password}
          />
          <Link className="ConfirmationSignIn-forgotPass" to="/forgotPassword">
            Forgot Password?
          </Link>
          <button type="submit" className="ConfirmationSignIn-button">
            {signInButtonText}
          </button>
          {errors.amplify.length ? (
            <span className="ConfirmationSignIn-error">
              {errors.amplify[0]}
            </span>
          ) : null}
        </form>
      ) : (
        <form
          className={`ConfirmationSignIn ${signingInClass}`}
          onSubmit={handleNewPassword}
        >
          <p className="ConfirmationSignIn-heading">
            A new password is required to manage your Amwell Private Practice
            subscription
          </p>
          <CheckoutFormField
            label={'Password'}
            name={'password'}
            id={'newPasswordForm-password'}
            value={newPasswordForm.password}
            onChange={handleNewPasswordFormInputChange}
            hideSymbol={true}
            type="password"
            errors={errors.password}
          />
          <button type="submit" className="ConfirmationSignIn-button">
            {signInButtonText}
          </button>
          {errors.amplify.length ? (
            <span className="ConfirmationSignIn-error">
              {errors.amplify[0]}
            </span>
          ) : null}
        </form>
      )}
    </div>
  );
}
